@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&family=PT+Sans:wght@400;700&family=PT+Serif:wght@700&display=swap');
html, body {
    font-family: 'PT Sans', sans-serif;
}

.page-wrapper {
    min-height: calc(100vh - 124px);
}

b {
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
}

* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
    outline: none !important;
}

h1, h2, h3, h4 {
    font-family: 'PT Serif', sans-serif;
    font-weight: 700;
}

.navbar-toggler {
    border: none;
}

p {
    font-family: 'PT Sans', sans-serif;
}
pre {
    font-family: 'PT Sans', sans-serif;
    font-size: 1rem;
}
.blue-job-title{
    color: rgb(0, 47, 120);
}
@media only screen and (min-width: 1200px) {
    .jamescape-container {
        max-width: 2000px;
    }
}

@media only screen and (min-width: 991px) {
    .navbar-link-margin {
        margin-left: auto;
    }
}

@media only screen and (max-width: 991px) {
    .navbar-link-margin {
        margin-left: 10px;
    }
}

.navbar {
    background-color: #002F78;
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
    width: 100%;
}

.navbar-brand {
    height: 100%;
    padding: 0;
}

.jamescape-logo-brand {
    max-height: 100px;
    height: 100%;
}

@media only screen and (max-width: 577px) {
    .jamescape-logo-brand {
        max-height: 15vw;
    }
}

@media only screen and (max-width: 577px) {
    .jamescape-logo-brand {
        max-height: 15vw;
    }
}

.jamescape-alert {
    border-radius: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.jamescape-light-blue {
    background-color: #F0F4FF;
}

.jamescape-red {
    background-color: #a60808;
    color: white;
}

.invalid {
    border-color: #a60808;
}

.invalid>.form-control {
    border: 1px solid #a60808;
}

.jamescape-light-grey {
    background-color: #EFEFEF;
}

.red-text {
    color: #a60808;
}

.jamescape-blue-button {
    background-color: #002F78 !important;
    color: white;
    font-family: 'PT Sans', sans-serif;
    padding: 10px;
    border: none !important;
    border-radius: 0;
}

.jamescape-blue-button:disabled {
    background-color: #002F78 !important;
    color: white;
    font-family: 'PT Sans', sans-serif;
    padding: 10px;
    border: none !important;
    border-radius: 0;
    opacity: 0.2;
}

.jamescape-transparant-button {
    background-color: #FFFFFF;
    color: black;
    font-family: 'PT Sans', sans-serif;
    padding: 10px;
    border: none;
    border-radius: 0;
}

.jamescape-red-button {
    border: none;
    background-color: #a60808;
    color: white;
    font-family: 'PT Sans', sans-serif;
    padding: 10px;
    border-radius: 0;
}

.beat-loader {
    margin: 0 auto;
}

.job-listing {
    padding-bottom: 20px;
}

.top-bottom-padding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav-link {
    color: white !important;
}

.active {
    color: #63A1FF !important;
}

.thumbContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
}

.thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}

.imgPreview {
    display: block;
    width: auto;
    height: 100%;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2;
    border-radius: 2;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.page-center {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.beat-loader {
    margin-bottom: -5px;
}

.clickable-link {
    cursor: pointer;
}

.form-control {
    border-radius: 0;
}

.sketch-picker {
    border-radius: 0 !important;
}

.footer {
    position: absolute;
    width: 100%;
    background-color: #002F78;
    padding: 10px;
    text-align: center;
    color: white;
    margin: 0;
    height: 100px;
}

.vertical-center {
    display: flex;
    align-items: center;
}

@media (max-width: 576px) {
    .container {
        max-width: 95vw;
    }
}
@media (min-width: 768px) {
    .footer-text{
        text-align: right;
    }
    .footer-text p{
        padding-right: 15px;
    }
}
@media (min-width: 1200px){
    .margin-fix{
        padding-left: 0;
    }
    .margin-fix-right{
        padding-right: 0;
        padding-left: 5px;
    }
}
@media (max-width: 1200px){
    .margin-fix{
        padding-left: 0;
        padding-right: 0;
    }
    .margin-fix-right{
        padding-top: 20px;
        padding-right: 0;
        padding-left: 0;
    }
}
.box{
    height: 100%;
    width: 100%;
}
.margin-top-10{
    margin-top: 10px;
}
.alert{
    border-radius: 0;
    border: 0;
}
.container{
    overflow: auto;
}
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.job-listing-grey{
    padding: 40px;
    margin-bottom: 20px;
    background-color: #f7f7f7;
}